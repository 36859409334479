.wrapper {
  position: relative;

  display: flex;
  flex-direction: column;
  min-height: 100vh;
  /* stylelint-disable-next-line */
  min-height: calc(100 * var(--vh, 1vh));
  overflow: hidden;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

  &--no-scrollbar {
    height: 100vh;
    /* stylelint-disable-next-line */
    height: calc(100 * var(--vh, 1vh));

    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  .header,
  .footer {
    flex-shrink: 0;
  }

  main {
    flex-grow: 1;
  }

  &.eng {
    font-weight: 400;
    font-family: $font-family--poppins;
  }
}

.visually-hidden {
  position: absolute;

  width: 1px;
  height: 1px;
  margin: -1px;

  clip: rect(0 0 0 0);
}

.no-transition {
  /* stylelint-disable-next-line */
  transition: none !important;
}

.rouble {
  font-family: $font-family--rouble;
  /* stylelint-disable */
  text-transform: lowercase !important;
  font-weight: 400 !important;
  /* stylelint-enable */
}

.eng {
  .vacancy--android-developer .vacancy__list--responsibility {
    width: size(551px);

    @include vp--1279 {
      width: 100%;
    }
  }

  .vacancy--ios-developer .vacancy__list--expectations {
    width: size(672px);

    @include vp--1279 {
      width: 100%;
    }
  }

  .vacancy--ua-manager .vacancy__list--responsibility {
    width: size(612px);

    @include vp--1279 {
      width: 100%;
    }
  }

  .intro__text-wrapper {
    max-width: size(1011px);
  }

  .intro__link {
    top: size(-1px);

    span {
      top: size(2px);
    }
  }

  .app__download-link {
    padding-top: size(26px);
    padding-bottom: size(22px);
  }

  .app--small .app__download-link {
    @include vp--1279 {
      padding-top: 18px;
      padding-bottom: 15px;
    }

    @include vp--767 {
      padding-top: 13px;
      padding-bottom: 10px;
    }
  }

  .request__button {
    padding-top: size(29px);
    padding-bottom: size(21px);

    @include vp--767 {
      padding: 17px 20px 11px;
    }
  }

  // .container--vacancy-extralarge {
  //   padding-right: size(5px);

  //   @include vp--1279 {
  //     padding: 0 32px;
  //   }

  //   @include vp--1023 {
  //     padding: 0 24px;
  //   }

  //   @include vp--767 {
  //     padding: 0 15px;
  //   }
  // }
}


// используйте для скрытия нежелательных анимаций-скачков при поворотах экрана
@keyframes disableBreakpointAnimation {
  0% {
    visibility: hidden;
    opacity: 0;
  }

  100% {
    visibility: hidden;
    opacity: 0;
  }
}
