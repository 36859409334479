.vacancy {
  padding-top: size(240px);
  padding-bottom: size(60px);

  @include vp--1279 {
    padding-top: size(235px);
  }

  @include vp--1023 {
    padding-top: 235px;
    padding-bottom: 50px;
  }

  @include vp--767 {
    padding-top: 204px;
    padding-bottom: 34px;
  }

  &.small-padding {
    padding-bottom: size(30px);

    @include vp--1023 {
      padding-bottom: 50px;
    }

    @include vp--767 {
      padding-bottom: 34px;
    }
  }

  &__heading {
    margin-bottom: size(48px);
    max-width: size(800px);
    transition: opacity 1s;
    transition-timing-function: cubic-bezier(0.65, 0.05, 0.36, 1);
    opacity: 0;

    .show & {
      opacity: 1;
    }

    @include vp--767 {
      margin-bottom: 39px;
    }
  }

  &__intro {
    position: relative;
    display: flex;
    justify-content: space-between;
    min-height: size(182px);
    margin-bottom: size(110px);

    @media (max-width: 1080px) {
      flex-direction: column;
    }

    @include vp--767 {
      margin-bottom: 73px;
    }
  }

  &__text {
    max-width: size(745px);
    margin-right: size(60px);
    transition: transform 1s, opacity 1s;
    transform: translateY(50px);
    transition-timing-function: cubic-bezier(0.65, 0.05, 0.36, 1);
    opacity: 0;

    .show & {
      transform: translateY(0);
      opacity: 1;
    }

    @media (max-width: 1080px) {
      margin-right: 0;
    }

    @include vp--767 {
      max-width: 94%;
      line-height: 28px;
    }
  }

  &__img {
    position: absolute;
    right: 0;
    fill: $color-transparent;
    transition: transform 1s, opacity 1s;
    transform: translateY(100px);
    transition-timing-function: cubic-bezier(0.65, 0.05, 0.36, 1);
    opacity: 0;

    .show & {
      transform: translateY(0);
      opacity: 1;
    }

    @media (max-width: 1080px) {
      position: static;
      align-self: center;
      margin-top: 80px;
    }

    @include vp--767 {
      margin-top: 70px;
    }
  }

  &__inner {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: size(-70px);

    @include vp--1023 {
      flex-direction: column;
      margin-bottom: -50px;
    }

    @include vp--767 {
      align-content: center;
      margin-bottom: -49px;
    }
  }

  &__list-block {
    display: flex;
    flex-direction: column;
    align-self: flex-start;

    @include vp--1279 {
      width: 45%;
    }

    @include vp--1023 {
      width: 80%;
    }

    @include vp--767 {
      width: 100%;
    }

  }

  &__list {
    display: flex;
    flex-direction: column;
    width: size(591px);
    margin-bottom: size(70px);
    transition: transform 1s, opacity 1s;
    transform: translateY(100px);
    transition-timing-function: cubic-bezier(0.65, 0.05, 0.36, 1);
    opacity: 0;

    .show & {
      transform: translateY(0);
      opacity: 1;
    }

    @include vp--1279 {
      width: 100%;
    }

    @include vp--1023 {
      margin-bottom: 50px;
    }

    @include vp--767 {
      margin-bottom: 49px;
      max-width: 100%;
      font-size: 12px;

    }
  }

  &__title {
    margin-bottom: size(24px);

    @include vp--767 {
      margin-bottom: 19px;
    }
  }

  &__items {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin: 0;
    padding: 0;
    list-style: none;
  }

  &__item {
    @include vp--767 {
      line-height: 18px;
    }
  }
}

.vacancy--android-developer {
  .vacancy__intro {
    max-width: size(1200px);
  }

  .vacancy__img {
    top: size(-96px);
    width: size(240px);
    height: size(284px);

    @include vp--767 {
      width: 191px;
      height: 226px;
      margin-top: 98px;
    }
  }

  .vacancy__list--responsibility {
    width: size(590px);

    @include vp--1279 {
      width: 100%;
    }
  }

  .vacancy__list--expectations {
    width: size(591px);

    @include vp--1279 {
      width: 100%;
    }
  }
}

.vacancy--ios-developer {
  .vacancy__intro {
    max-width: size(1191px);
  }

  .vacancy__img {
    top: size(-93px);
    width: size(222px);
    height: size(264px);

    @include vp--767 {
      width: 177px;
      height: 210px;
      margin-top: 95px;
    }
  }

  .vacancy__list--expectations {
    width: size(652px);

    @include vp--1279 {
      width: 100%;
    }
  }
}

.vacancy--ua-manager {
  .vacancy__intro {
    max-width: size(1211px);
  }

  .vacancy__img {
    top: size(-109px);
    width: size(283px);
    height: size(284px);

    @include vp--767 {
      width: 216px;
      height: 216px;
      margin-top: 109px;
    }
  }

  .vacancy__list--responsibility {
    width: size(591px);

    @include vp--1279 {
      width: 100%;
    }
  }
}

.vacancy--senior-designer {
  .vacancy__intro {
    max-width: size(1231px);
  }

  .vacancy__img {
    top: size(-93px);
    width: size(251px);
    height: size(285px);

    @include vp--767 {
      width: 196px;
      height: 223px;
      margin-top: 96px;
    }
  }

  .vacancy__list--responsibility {
    width: size(686px);

    @include vp--1279 {
      width: 100%;
    }
  }

  .vacancy__list--expectations {
    width: size(591px);

    @include vp--1279 {
      width: 100%;
    }
  }
}

.vacancy--product-manager {
  .vacancy__intro {
    max-width: size(1240px);
    margin-bottom: size(111px);
  }

  .vacancy__img {
    top: size(-114px);
    width: size(260px);
    height: size(283px);

    @include vp--767 {
      width: 205px;
      height: 223px;
      margin-top: 92px;
    }
  }

  .vacancy__list--responsibility {
    width: size(745px);

    @include vp--1279 {
      width: 100%;
    }
  }

  .vacancy__list--expectations {
    width: size(654px);
    padding-right: size(30px);

    @include vp--1279 {
      width: 100%;
    }

    @include vp--767 {
      padding-right: 0;
    }
  }
}
