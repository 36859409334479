// Vendor
//---------------------------------

@import "vendor/normalize";
@import "vendor/swiper";

// Global
//---------------------------------

@import "variables";
@import "mixins";
@import "functions";
@import "global/fonts";
@import "global/reboot";
@import "global/utils";
@import "global/container";

// Blocks
//---------------------------------

@import "blocks/preloader";
@import "blocks/header";
@import "blocks/main-nav";
@import "blocks/footer";
@import "blocks/modal";
@import "blocks/menu-burger";
@import "blocks/text";
@import "blocks/our-apps";
@import "blocks/app";
@import "blocks/job-openings";
@import "blocks/job-features";
@import "blocks/job-vacancies";
@import "blocks/vacancy-card";
@import "blocks/request";
@import "blocks/intro";
@import "blocks/advantages";
@import "blocks/apps";
@import "blocks/vacancy";
@import "blocks/private-policy";
@import "blocks/not-found";
@import "blocks/growth";
@import "blocks/about";
@import "blocks/contacts";

// используйте это для отключения ховеров на тач устройствах
// ! но не используйте это для текстовых полей ( input, textarea )
// также для любых transition обязательно указывайте transition-property
// transition: color $default-transition-settings, а не transition: $default-transition-settings

/* @media (hover: hover), screen and (min-width: 0\0) {
  &:hover,
  &:focus {
    color: red;
  }
} */
