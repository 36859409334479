.not-found {
  width: size(1454px);
  padding-top: size(186px);
  padding-bottom: size(20px);
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  @include vp--767 {
    flex-direction: column;
    padding-top: 143px;
    padding-bottom: 0;
  }

  &__inner {
    display: flex;
    flex-direction: column;
    padding-top: size(68px);
    transition: opacity 1s;
    transition-timing-function: cubic-bezier(0.65, 0.05, 0.36, 1);
    opacity: 0;

    .show & {
      opacity: 1;
    }
  }

  &__title {
    @include vp--767 {
      margin-bottom: 40px;
    }
  }

  &__wrapper {
    transition: transform 1s;
    transform: translateY(50px);
    transition-timing-function: cubic-bezier(0.65, 0.05, 0.36, 1);

    .show & {
      transform: translateY(0);
    }
  }

  &__sub-title {
    margin-bottom: size(28px);

    @include vp--767 {
      margin-bottom: 7px;
    }
  }

  &__text {
    margin: 0;
    max-width: size(250px);

    @include vp--767 {
      font-size: 14px;
      line-height: 24px;
    }
  }

  &__gif {
    display: flex;
    width: size(380px);
    height: size(700px);
    transition: opacity 1s;
    transition-timing-function: cubic-bezier(0.65, 0.05, 0.36, 1);
    opacity: 0;
    position: relative;
    left: 17px;

    .show & {
      opacity: 1;
    }

    @include vp--767 {
      align-self: center;
      width: 264px;
      height: 486px;
      margin-top: -20px;
      margin-bottom: -26px;
    }
  }
}

.not-found--en .not-found__text {
  max-width: size(210px);

  @include vp--767 {
    max-width: 200px;
  }
}
