.private-policy {
  padding-top: size(240px);
  padding-bottom: size(172px);

  @include vp--767 {
    padding-top: 204px;
    padding-bottom: 25px;
  }

  &__heading {
    margin-bottom: size(101px);
    transition: opacity 1s;
    transition-timing-function: cubic-bezier(0.65, 0.05, 0.36, 1);
    opacity: 0;

    .show & {
      opacity: 1;
    }

    @include vp--767 {
      margin-bottom: 48px;
    }
  }

  &__list {
    margin: 0;
    padding: 0;
    list-style: none;
    transition: transform 1s, opacity 1s;
    transform: translateY(50px);
    transition-timing-function: cubic-bezier(0.65, 0.05, 0.36, 1);
    opacity: 0;

    .show & {
      transform: translateY(0);
      opacity: 1;
    }
  }

  &__item {
    margin-bottom: size(30px);

    @include vp--767 {
      margin-bottom: 48px;
    }
  }

  &__title {
    margin-bottom: size(20px);

    @include vp--767 {
      margin-bottom: 22px;
    }
  }

  &__text {
    margin: 0;
    font-weight: 500;

    @include vp--767 {
      font-size: 12px;
      line-height: 18px;
    }
  }
}


.private-policy--en {
  padding-bottom: size(169px);

  .private-policy__heading {
    @include vp--767 {
      margin-bottom: 62px;
    }
  }
}
