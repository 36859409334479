.vacancy-card {
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  border: size(1px) solid $color-default-black;
  border-radius: size(25px);
  overflow: hidden;
  position: relative;
  background: linear-gradient(to right, $color-transparent 81.9%, $color-default-black size(162px));
  transition: transform 1s, opacity 1s, border-color $trans--300;
  transition-timing-function: cubic-bezier(0.65, 0.05, 0.36, 1);
  transform: translateY(100px);
  opacity: 0;

  .show & {
    transform: translateY(0);
    opacity: 1;
  }

  @include vp--767 {
    border-radius: 12.5px;
  }

  &::after {
    content: "";
    position: absolute;
    width: size(51px);
    height: size(8px);
    right: 5.3%;
    top: calc(50% - #{size(4px)});

    background-image: url("../img/svg/arrow-right-desktop.svg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;

    @include vp--767 {
      right: 6.3%;
      width: 26px;
      background-image: url("../img/svg/arrow-right-mobile.svg");
    }

    @media (max-width: 450px) {
      right: 5.3%;
    }
  }

  &--disabled {
    opacity: 0;
    pointer-events: none;

    .show & {
      opacity: 0.15;
    }
  }

  &__wrapper {
    position: relative;
    width: 100%;
    padding-top: size(13px);
    padding-bottom: size(13px);
    padding-left: size(25px);
    padding-right: size(25px);
    margin-right: 18.1%;

    @include vp--767 {
      padding-top: 8px;
      padding-bottom: 8px;
      padding-left: 13px;
      padding-right: 13px;
    }

    &::after {
      content: "";
      position: absolute;
      background-image: url("../img/svg/bg-desktop-jobs-opening.svg");
      background-repeat: no-repeat;
      background-size: cover;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      z-index: -1;
      opacity: 0;
      transition: opacity $trans--300;
    }
  }

  &__wrapper::before {
    content: "";
    position: absolute;
    width: 90%;
    height: size(1px);
    top: calc(50% - #{size(1px)});
    bottom: 0;
    background-color: $color-default-black;
    transition: box-shadow $trans--300, background-color $trans--300;

    @include vp--767 {
      width: 93%;
    }

    @media (max-width: 450px) {
      width: 89%;
    }
  }

  &__title {
    margin-bottom: size(8px);
    transition: color $trans--300;

    @include vp--767 {
      margin-bottom: 24px;
    }
  }

  &__description {
    max-width: 93%;
    position: relative;
    height: size(108px);
    margin-top: 0;
    margin-bottom: size(49px);

    font-size: size(14px);
    line-height: size(21px);
    overflow: hidden;
    text-overflow: ellipsis;
    //stylelint-disable-next-line
    display: -webkit-box;
    -webkit-line-clamp: 5;
    //stylelint-disable-next-line
    -webkit-box-orient: vertical;

    @include vp--767 {
      position: relative;
      margin-bottom: 44px;
      height: 72px;
      font-size: 12px;
      line-height: 18px;
      -webkit-line-clamp: 4;
    }
  }

  &__requirement {
    max-width: 99%;
  }

  &__requirements {
    position: relative;
    margin: 0;
    padding: 0;
    list-style: none;
    font-size: size(14px);
    line-height: size(21px);

    overflow: hidden;
    text-overflow: ellipsis;
    //stylelint-disable-next-line
    display: -webkit-box;
    -webkit-line-clamp: 5;
    //stylelint-disable-next-line
    -webkit-box-orient: vertical;

    @include vp--767 {
      height: 105px;
    }
  }

  &.active,
  &:active {
    border-color: $color-default-white;
  }

  &.active .vacancy-card__wrapper::before,
  &:active .vacancy-card__wrapper::before {
    box-shadow: 0 size(-1px) size(1px) rgba(0, 0, 0, 0.5);
    background-color: $color-default-white;
  }

  &.active .vacancy-card__title,
  &:active .vacancy-card__title {
    color: $color-default-white;
  }

  &.active .vacancy-card__wrapper::after,
  &:active .vacancy-card__wrapper::after {
    opacity: 1;
  }
}
