.app {
  display: flex;
  padding: size(20px);
  border-radius: size(25px);
  border: size(1px) solid $color-default-black;
  min-height: size(608px);
  font-size: size(14px);
  line-height: size(21px);
  position: relative;
  overflow: hidden;
  cursor: pointer;
  transform: translateZ(0);

  @include vp--1023 {
    flex-direction: column;
    position: relative;
  }

  @include vp--767 {
    border-radius: 20px;
    padding: 15px;
    line-height: 22px;
  }

  &::before {
    content: "";
    position: absolute;
    background-image: url("../img/svg/bg-desktop-apps.svg");
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: -1;
    opacity: 0;
    transition: opacity $trans--300;
    transform: translateZ(0);
  }

  &.active,
  &.active .app__image,
  &.active .app__header,
  &.active .app__features {
    border-color: $color-default-white;
    transform: translateZ(0);
  }

  &.active .app__installs span:first-child,
  &.active .app__download-link:first-child {
    color: $color-default-white;
    transform: translateZ(0);
  }

  &.active .app__download-link:first-child {
    background-color: $color-default-black;
    transform: translateZ(0);
  }

  &.active .app__platforms svg {
    fill: $color-default-white;
    transform: translateZ(0);

    @include vp--767 {
      transform: scale(0.6) translateZ(0);
    }
  }

  &.active::before,
  &.active .app__header::before,
  &.active .app__features::before {
    opacity: 1;
    transform: translateZ(0);
  }
}

.app__wrapper {
  display: flex;
  flex-direction: column;
  margin-right: size(20px);
  width: 100%;

  @include vp--1023 {
    margin-right: 0;
    margin-bottom: 20px;
  }

  @include vp--767 {
    margin-bottom: 116px;
  }
}

.app__header {
  display: flex;
  border-bottom: size(1px) solid $color-default-black;
  padding-bottom: size(20px);
  margin-bottom: size(30px);
  transition: border-color $trans--300;
  position: relative;
  transform: translateZ(0);

  @include vp--1023 {
    flex-direction: row;
  }

  @include vp--767 {
    padding-bottom: 24px;
    margin-bottom: 22px;
  }

  &::before {
    content: "";
    position: absolute;
    bottom: -1px;
    height: 1px;
    width: 100%;
    opacity: 0;
    transition: opacity $trans--300;
    box-shadow: 0 -1px 1px rgba(0, 0, 0, 0.5);
    transform: translateZ(0);
  }
}

.app__header-wrapper {
  margin-left: size(20px);

  @include vp--767 {
    margin-left: 12px;
  }
}

.app__icon {
  flex-shrink: 0;
  width: size(124px);
  height: size(124px);
  border-radius: size(25px);
  overflow: hidden;

  @include vp--767 {
    width: 76px;
    height: 76px;
    border-radius: 15px;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.app__name {
  font-weight: 600;
  font-size: size(48px);
  line-height: size(48px);
  letter-spacing: -0.03em;
  margin-top: 0;
  margin-bottom: size(20px);

  @include vp--1279 {
    font-size: 36px;
    line-height: 36px;
  }

  @include vp--767 {
    font-size: 26px;
    line-height: 26px;
    margin-bottom: 32px;
  }
}

.app__installs {
  margin-top: 0;
  margin-bottom: 0;
  font-weight: 600;
  font-size: size(30px);
  line-height: size(45px);
  text-transform: uppercase;

  @include vp--767 {
    font-size: 16px;
    line-height: 24px;
  }

  span:first-child {
    transition: color $trans--300;
    transform: translateZ(0);
  }
}

.app__features {
  margin-top: 0;
  margin-bottom: size(51px);
  padding-left: size(1px);
  list-style: none;
  text-indent: size(8px);
  padding-bottom: size(27px);
  border-bottom: size(1px) solid $color-default-black;
  transition: border-color $trans--300;
  position: relative;
  transform: translateZ(0);

  @include vp--767 {
    padding-bottom: 39px;
    font-size: 12px;
    line-height: 18px;
    margin-bottom: 28px;
    letter-spacing: -0.04em;
  }

  &::before {
    content: "";
    position: absolute;
    bottom: -1px;
    height: 1px;
    left: 0;
    width: 100%;
    opacity: 0;
    transition: opacity $trans--300;
    transform: translateZ(0);
    box-shadow: 0 -1px 1px rgba(0, 0, 0, 0.5);
  }
}

.app__features-item {
  position: relative;

  &::before {
    content: "";
    width: size(4px);
    height: size(4px);
    left: 0;
    top: size(8px);
    border-radius: 50%;
    background-color: $color-default-black;
    position: absolute;

    @include vp--767 {
      width: 3px;
      height: 3px;
      top: 8px;
    }
  }

  @include vp--767 {
    margin-bottom: 3px;
  }
}

.app__features-item:last-of-type {
  @include vp--767 {
    margin-bottom: 0;
  }
}

.app__description {
  margin-top: 0;
  margin-bottom: auto;
  max-width: size(632px);

}

.app__footer {
  display: flex;
  margin-top: size(20px);
  justify-content: space-between;

  @include vp--1023 {
    position: absolute;
    bottom: 20px;
    width: calc(100% - 40px);
  }

  @include vp--767 {
    width: calc(100% - 30px);
  }
}

.app__platforms {
  margin-right: size(55px);
  display: flex;
  align-items: center;

  @include vp--1279 {
    margin-right: 0;
    margin-left: 15px;
  }

  @include vp--767 {
    display: none;
  }

  svg + svg {
    margin-left: size(12px);
  }

  svg {
    transition: fill $trans--300;
    transform: translateZ(0);
    width: size(35px);
    height: size(43px);
  }
}

.app__platforms-hidden {
  visibility: hidden;

  @include vp--1023 {
    display: none;
  }
}

.app__download-link {
  font-weight: 600;
  font-size: size(24px);
  line-height: size(36px);
  text-transform: uppercase;
  border: size(1px) solid $color-default-black;
  border-radius: size(25px);
  padding-top: size(25px);
  padding-bottom: size(25px);
  padding-left: size(93px);
  padding-right: size(93px);
  display: block;
  text-align: center;
  width: 100%;
  max-width: size(410px);
  min-height: size(86px);
  transition: color $trans--300, background-color $trans--300;
  transform: translateZ(0);

  @include vp--1279 {
    border-radius: 20px;
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 20px;
    padding-right: 20px;
    min-height: inherit;
  }

  @include vp--1023 {
    padding-left: 50px;
    padding-right: 50px;
  }

  @include vp--767 {
    font-size: 14px;
    line-height: 21px;
    width: 100%;
  }
}

.app__image {
  flex-shrink: 0;
  border: size(1px) solid $color-default-black;
  border-radius: size(25px);
  overflow: hidden;
  width: size(460px);
  transition: border-color $trans--300;
  position: relative;
  transform: translateZ(0);
  max-height: size(608px);

  @include vp--1279 {
    width: 400px;
  }

  @include vp--1023 {
    width: 460px;
    align-self: center;
    margin-bottom: 98px;
  }

  @include vp--767 {
    width: 100%;
    border-radius: 17px;
    max-width: size(410px);
    height: size(413px);
  }

  img {
    width: size(460px);
    height: size(920px);
    object-fit: cover;
    object-position: top;
    position: relative;
    top: size(-1px);

    @include vp--767 {
      height: auto;
      width: 100%;
    }
  }
}

.app--small {
  margin-bottom: 0;
  margin-right: size(45px);
  max-width: size(450px);
  flex-shrink: 1;
  transition: transform 1s, opacity 1s, border-color $trans--300;
  transition-timing-function: cubic-bezier(0.65, 0.05, 0.36, 1);
  padding-top: size(25px);
  min-height: size(650px);
  transform: translateZ(0);

  @include vp--1279 {
    margin-right: 30px;
    min-height: 555px;
  }

  @include vp--1023 {
    margin-right: 0;
    flex-shrink: 0;
    min-height: 550px;
    max-width: 400px;
  }

  @include vp--767 {
    margin-bottom: 0;
    padding: 10px;
    max-width: 250px;
    border-radius: 14px;
    min-height: 360px;
    height: auto;

    margin-top: 2px;
  }

  @media screen and (min-aspect-ratio: 1366/680) {
    min-height: size(600px);
  }

  &::before {
    background-image: url("../img/svg/bg-apps-small.svg");
  }

  &:active,
  &:active .app__image,
  &:active .app__header,
  &:active .app__features {
    border-color: $color-default-white;
  }

  &:active .app__installs span:first-child,
  &:active .app__download-link:first-child {
    color: $color-default-white;
  }

  &:active .app__download-link:first-child {
    background-color: $color-default-black;
  }

  &:active .app__platforms svg {
    fill: $color-default-white;
  }

  &:active::before,
  &:active .app__header::before,
  &:active .app__features::before {
    opacity: 1;
  }
}

.app--small:nth-child(1) {
  transform: translate3d(0, 100px, 0);
  backface-visibility: hidden;
  opacity: 0;

  .show & {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}

.app--small:nth-child(2) {
  margin-top: size(75px);
  transform: translate3d(0, 200px, 0);
  backface-visibility: hidden;
  opacity: 0;

  .show & {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }

  @include vp--1279 {
    margin-top: 20px;
  }

  @include vp--767 {
    margin-top: 0;

    .app__platforms svg {
      margin-left: -6px;
    }
  }
}

.app--small:nth-child(3) {
  margin-top: size(150px);
  transform: translate3d(0, 400px, 0);
  backface-visibility: hidden;
  opacity: 0;

  .show & {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }

  @include vp--1279 {
    margin-top: 40px;
  }

  @include vp--767 {
    margin-top: 0;
  }
}

.app--small:last-child {
  margin-right: 0;
}

.app--small .app__header {
  padding-bottom: size(25px);
  margin-bottom: size(50px);

  @include vp--1279 {
    margin-bottom: 20px;
    padding-bottom: 20px;
  }

  @include vp--767 {
    padding-bottom: 0;
    margin-bottom: 27px;
  }
}

.app--small .app__icon {
  width: size(112px);
  height: size(112px);

  @include vp--1279 {
    width: 84px;
    height: 84px;
    border-radius: 20px;
  }

  @include vp--767 {
    width: 62px;
    height: 62px;
    border-radius: 15px;
  }
}

.app--small .app__wrapper {
  margin-right: 0;

  @include vp--1023 {
    margin-bottom: 0;
    flex-basis: 510px;
  }

  @include vp--767 {
    height: 100%;
    flex-basis: auto;
  }
}

.app--small .app__header-wrapper {
  margin-left: 0;
  margin-right: size(40px);

  @include vp--1279 {
    margin-right: 20px;
  }

  @include vp--767 {
    margin-right: 40px;
  }
}

.app--small .app__name {
  margin-bottom: size(90px);
  max-width: 80%;

  @include vp--1279 {
    font-size: 36px;
    line-height: 36px;
    margin-bottom: 20px;
  }

  @include vp--767 {
    font-size: 27px;
    line-height: 27px;
    letter-spacing: -0.03em;
    margin-bottom: 43px;
  }


  @media screen and (min-aspect-ratio: 1366/680) {
    margin-bottom: size(60px);
  }
}

.app--small .app__platforms {
  @include vp--1279 {
    margin-left: 0;
  }

  @include vp--767 {
    display: block;
    height: 43px;
  }

  svg {
    @include vp--767 {
      transform: scale(0.6);
      margin-left: 20px;
    }
  }
}

.app--small .app__installs {
  margin-bottom: size(30px);

  @include vp--1279 {
    font-size: 23px;
    line-height: 34px;
    margin-bottom: 20px;
  }

  @include vp--767 {
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 19px;
  }
}

.app--small .app__description {
  @include vp--1279 {
    margin-bottom: auto;
  }

  @include vp--767 {
    font-size: 12px;
    line-height: 18px;
    /* stylelint-disable-next-line */
    display: -webkit-box;
    /* stylelint-disable-next-line */
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 4;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-bottom: auto;
  }
}

.app--small .app__footer {
  margin-top: size(35px);

  @include vp--1023 {
    position: static;
    width: 100%;
  }

  @include vp--767 {
    margin-top: 5px;
  }
}

.app--small .app__download-link {
  @include vp--1279 {
    font-size: 18px;
    line-height: 27px;
    padding-top: 15px;
    padding-bottom: 15px;
    padding-left: 20px;
    padding-right: 20px;
    border-radius: 20px;
  }

  @include vp--767 {
    padding-top: 10px;
    padding-bottom: 10px;
    border-radius: 14px;
    font-size: 14px;
    line-height: 21px;
  }
}
