.job-vacncies {
  padding-top: size(75px);
  padding-bottom: size(50px);

  @include vp--767 {
    padding-bottom: 14px;
    padding-top: 61px;
  }

  &__heading {
    margin-bottom: size(67px);
    transition: opacity 1s;
    transition-timing-function: cubic-bezier(0.65, 0.05, 0.36, 1);
    opacity: 0;

    .show & {
      opacity: 1;
    }

    @include vp--767 {
      margin-bottom: 58px;
    }
  }

  &__list {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: size(30px);
    margin: 0;
    padding: 0;
    list-style: none;

    @include vp--1279 {
      grid-template-columns: 1fr;
      justify-items: center;
    }
  }

  &__item {
    max-width: size(685px);
    min-height: size(375px);
    margin-bottom: size(70px);

    @include vp--1279 {
      margin-bottom: 55px;
    }

    @include vp--767 {
      min-height: 306px;
    }
  }

  &__item:nth-child(2n) {
    margin-left: auto;

    @include vp--1279 {
      margin-left: 0;
    }
  }
}
