.job-features {
  padding-top: size(57px);

  @include vp--767 {
    padding-top: 10px;
    padding-bottom: 46px;
  }

  &__inner {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    max-width: size(1078px);

    @include vp--767 {
      flex-direction: column;
    }
  }

  &__heading {
    margin-bottom: size(57px);
    transition: opacity 1s;
    transition-timing-function: cubic-bezier(0.65, 0.05, 0.36, 1);
    opacity: 0;

    .show & {
      opacity: 1;
    }

    @include vp--767 {
      margin-bottom: 40px;
      font-size: 16px;
      line-height: 24px;
    }
  }

  &__list {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    margin: 0;
    padding: 0;
    list-style: none;
  }

  &__list:first-child {
    max-width: 622px;
    margin-right: size(30px);

    @include vp--767 {
      margin-right: 0;
    }
  }

  &__list:last-child {
    max-width: 452px;
  }

  &__item {
    margin-bottom: size(45px);
    transition: transform 1s, opacity 1s;
    transition-timing-function: cubic-bezier(0.65, 0.05, 0.36, 1);
    transform: translate3d(0, 100px, 0);
    backface-visibility: hidden;
    opacity: 0;

    .show & {
      transform: translate3d(0, 0, 0);
      opacity: 1;
    }

    @include vp--767 {
      margin-bottom: 7px;
    }
  }

  &__title {
    margin-top: 0;
    margin-bottom: size(2px);

    @include vp--767 {
      margin: 0;
    }
  }

  &__text {
    margin: 0;

    @include vp--767 {
      display: none;
    }
  }
}
