.intro {
  padding-top: size(240px);
  padding-bottom: size(132px);
  display: flex;
  justify-content: space-between;
  position: relative;

  @include vp--1023 {
    flex-direction: column;
    align-items: center;
    padding-top: 201px;
    padding-bottom: 100px;
  }

  @include vp--767 {
    padding-bottom: 40px;
  }
}

.intro__text {
  transition: transform 1s, opacity 1s;
  transform: translate3d(0, -50px, 0);
  backface-visibility: hidden;
  opacity: 0;

  @include vp--1279 {
    font-size: 32px;
    line-height: 48px;
  }

  @include vp--767 {
    font-size: 23px;
    line-height: 40px;
  }

  .show & {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.intro__text-wrapper {
  max-width: size(1020px);
  margin-bottom: size(93px);

  @include vp--1279 {
    max-width: 520px;
  }

  @include vp--1023 {
    margin-bottom: 72px;
    max-width: 80%;
  }

  @include vp--767 {
    max-width: 100%;
    margin-bottom: 150px;
  }

  @include vp--374 {
    max-width: 100%;
  }
}

.intro__image {
  flex-shrink: 0;
  padding-top: size(107px);
  opacity: 0;
  transform: scale(0.5);
  transition: transform 1s, opacity 1s;
  transform-origin: 50% 0;

  .show & {
    opacity: 1;
    transform: scale(1);
  }

  @include vp--1279 {
    padding-top: 0;
  }

  img {
    width: size(372px);
    height: size(372px);

    @include vp--767 {
      width: 241px;
      height: 241px;
    }
  }
}

.intro__link {
  border-radius: size(25px);
  display: inline-block;
  position: relative;
  background-color: $color-default-black;
  color: $color-default-white;
  line-height: size(86px);
  transition:
    background-color $trans--300,
    color $trans--300,
    border-color $trans--300,
    transform 1.1s 100ms;

  transform: translate3d(0, -25px, 0);
  backface-visibility: hidden;

  padding-left: size(11px);
  padding-right: size(11px);
  margin-left: size(-9px);
  margin-right: size(-9px);
  top: size(2px);

  .show & {
    transform: translate3d(0, 0, 0);
  }

  @include vp--1279 {
    font-size: 32px;
    line-height: 48px;
    border-radius: 17px;
    top: 2px;
    margin-right: 0;
    margin-left: 0;
  }

  @include vp--767 {
    font-size: 23px;
    line-height: 30px;
    border-radius: 9px;
    top: 0;

    padding-left: 3px;
    padding-right: 3px;
    margin-left: -4px;
    margin-right: -3px;
  }

  &::before {
    content: "";
    position: absolute;
    background-color: #c2a6f1;
    background-image: linear-gradient(123.35deg, #ebf3d0 0%, rgba(235, 243, 208, 0) 18.4%), radial-gradient(29.9% 70.94% at 44.25% 86.96%, #dc8ddc 0%, rgba(220, 141, 220, 0) 100%), radial-gradient(33.83% 53.57% at 35.87% 100%, #dc8ddc 0%, rgba(220, 141, 220, 0) 100%), radial-gradient(42.66% 49.72% at 45.56% 44.65%, #cbadeb 0%, rgba(194, 166, 241, 0) 100%), linear-gradient(134.59deg, #cdf9e8 20.63%, rgba(205, 249, 232, 0) 47.84%), linear-gradient(216.44deg, rgba(192, 169, 240, 0) -16.52%, #c0a9f0 -1.04%, rgba(192, 169, 240, 0) 16.99%), linear-gradient(128.53deg, rgba(192, 169, 240, 0) 28.63%, #c0a9f0 38.5%, rgba(192, 169, 240, 0) 50.26%), radial-gradient(40.75% 97.37% at 90.75% 40.15%, #fffdb1 0%, #fee4bf 34.46%, #f0bdd0 69.5%, rgba(255, 129, 38, 0) 100%);
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    border-radius: size(25px);
    opacity: 0;
    z-index: 1;
    font-weight: 600;
    font-size: size(43px);
    line-height: size(64px);
    padding: size(10px);
    text-align: center;
    transition: opacity $trans--300;

    @include vp--1279 {
      border-radius: 17px;
      font-size: 21px;
      line-height: 26px;
    }

    @include vp--767 {
      border-radius: 9px;
      font-size: 16px;
      line-height: 29px;
      padding: 0;
    }
  }


  &.active,
  &:active {
    background-color: $color-transparent;
    border-color: $color-transparent;

    &::before {
      opacity: 1;
    }
  }

  span {
    position: relative;
    top: size(-3px);

    @include vp--1279 {
      top: 0;
    }
  }
}

.intro__link--apps {
  @include vp--1279 {
    top: -2px;
  }
  &::before {
    content: "наши приложения";
  }
}

.intro__link--job {
  border: size(1px) solid $color-default-black;
  font-weight: 400;
  line-height: size(84px);
  padding-left: size(10px);
  background-color: $color-transparent;
  color: $color-default-black;
  position: relative;
  left: size(-10px);
  padding-right: size(17px);
  margin-left: 0;
  margin-right: 0;

  @include vp--1279 {
    left: 0;
  }

  @include vp--1279 {
    line-height: 46px;
  }

  @include vp--767 {
    border-radius: 11px;
    line-height: 30px;
    padding-left: 3px;
    padding-right: 4px;
    margin-left: -2px;
    margin-right: 2px;

    span {
      left: 0;
      top: 0;
    }
  }

  span {
    left: size(3px);
  }

  &::before {
    content: "вакансии";
    color: $color-default-white;
    line-height: size(53px);
    font-size: size(64px);
    
    @include vp--1279 {
      font-size: 32px;
      line-height: 40px;
      padding-top: 0;
      padding-bottom: 0;
    }

    @include vp--767 {
      font-size: 23px;
      line-height: 26px;
    }
  }
}

.intro__arrow-button {
  position: absolute;
  bottom: 0;
  left: 50%;
  opacity: 0;
  border: 0;
  background-color: $color-transparent;
  width: size(50px);
  height: size(91px);
  padding: 0;
  cursor: pointer;
  transition: opacity 1s, transform 1s;
  transform: translate3d(-50%, -100px, 0);
  backface-visibility: hidden;

  svg {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate3d(-50%, -50%, 0);
    width: size(8px);
    height: size(91px);
  }

  .show & {
    transform: translate3d(-50%, 0, 0);
    opacity: 1;
  }

  @include vp--1023 {
    display: none;
  }
}

.intro--eng {
  .intro__link {
    &::before {
      content: "";
      z-index: 0;
    }

    &.active,
    &:active {
      color: $color-default-white;
    }
  }
}
