.our-apps {
  padding-top: size(236px);
  padding-bottom: size(208px);

  @include vp--1439 {
    padding-top: size(236px);
    padding-bottom: size(208px);
  }

  @include vp--1279 {
    padding-top: 236px;
    padding-bottom: 208px;
  }

  @include vp--1023 {
    padding-top: 236px;
    padding-bottom: 208px;
  }

  @include vp--767 {
    padding-top: 204px;
    padding-bottom: 80px;
  }
}

.our-apps__title {
  margin-bottom: size(67px);
  transition: opacity 1s;
  transition-timing-function: cubic-bezier(0.65, 0.05, 0.36, 1);
  opacity: 0;

  .show & {
    transform: translateY(0);
    opacity: 1;
  }

  @include vp--767 {
    margin-bottom: 47px;
  }
}

.our-apps__list {
  margin-top: 0;
  margin-bottom: 0;
  padding-left: 0;
  list-style: none;
  display: flex;
  flex-direction: column;
}

.our-apps__item {
  margin-bottom: size(141px);
  transition: transform 1s, opacity 1s, border $trans--300;
  transition-timing-function: cubic-bezier(0.65, 0.05, 0.36, 1);
  transform: translateY(100px);
  opacity: 0;

  .show & {
    transform: translateY(0);
    opacity: 1;
  }

  @include vp--767 {
    margin-bottom: 55px;
  }
}

.our-apps__item:last-of-type {
  margin-bottom: 0;
}
