.main-nav {
  display: flex;
  max-width: size(610px);
  width: 100%;
  transition: transform 1.2s, opacity 1.2s;
  transform: translate3d(0, -50px, 0);
  backface-visibility: hidden;
  opacity: 0;

  .show & {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }

  @include vp--1023 {
    max-width: size(450px);
  }

  @include vp--767 {
    display: none;
  }

  &__wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }

  &__list {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin: 0;
    padding: 0;
    list-style: none;
  }

  &__item {
    margin-right: size(153px);

    &:last-child {
      margin-right: 0;
    }

    @include vp--1279 {
      margin-right: 135px;
    }

    @include vp--1023 {
      margin-right: 70px;
    }
  }

  &__link {
    display: block;
    font-size: size(15px);
    line-height: size(21px);
    font-weight: 500;
    color: $color-default-black;
    cursor: pointer;

    transition: text-shadow $trans--400;

    &--active {
      color: $color-light-hawkes-blue;
      pointer-events: none;
    }
  }

  .main-nav__link:active {
    text-shadow: 0 0 size(0.6px) $color-default-black, 0 0 size(0.6px) $color-default-black;
  }

  @media (hover: hover), screen and (min-width: 0\0) {
    .main-nav__link:hover,
    .main-nav__link:focus {
      text-shadow: 0 0 size(0.6px) $color-default-black, 0 0 size(0.6px) $color-default-black;
    }
  }
}

.main-nav__list--language {
  .main-nav__item {
    max-width: size(40px);
    margin-right: size(15px);

    &:last-child {
      margin-right: 0;
    }
  }
}

.header--en {
  .main-nav {
    max-width: size(545px);

    @include vp--1023 {
      max-width: 430px;
    }
  }

  .main-nav__item:first-child {
    margin-right: size(139px);

    @include vp--1023 {
      margin-right: 70px;
    }

    @include vp--767 {
      margin-right: 0;
    }
  }

  .main-nav__list--language {
    .main-nav__item:first-child {
      margin-right: size(19px);
    }
  }
}
