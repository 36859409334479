.menu-burger {
  display: none;

  transform: translateX(-100%);
  z-index: 16;

  &--open {
    transform: translateX(0%);
    transition: transform $trans--400;

    .menu-burger__overlay {
      visibility: visible;
    }

    .menu-burger__header {
      position: relative;
      margin-bottom: 0;
    }

    .main-nav {
      margin-top: -120px;
    }
  }

  @include vp--767 {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    width: 100%;
    height: 100vh;
    /* stylelint-disable-next-line */
    height: calc(100 * var(--vh, 1vh));
    overflow-y: hidden;
    background-color: $color-default-black;
    transition: transform $trans--600;
    animation: disableBreakpointAnimation 0.1ms;
  }

  &__wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    overflow-y: auto;

    z-index: 16;
  }

  &__header {
    position: fixed;
    top: 0;
    display: flex;
    align-items: center;
    width: 100%;
    padding-top: 54px;
    padding-bottom: 20px;
    z-index: 1;
  }

  &__overlay {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 15;
    width: 100%;
    height: 100vh;
    background-color: transparent;
    visibility: hidden;
    opacity: 0;
  }

  &__toggle {
    position: absolute;
    left: 15px;
    display: block;
    background-color: transparent;
    border: 0;
    padding: 0;
    color: $color-default-white;

    z-index: 4;

    svg {
      fill: $color-default-white;
    }
  }

  .main-nav {
    display: flex;
    max-width: 100%;
    width: 100%;
    margin-bottom: 13px;
  }

  .main-nav__wrapper {
    display: flex;
    flex-direction: column;
    margin-left: 15px;
    margin-right: 15px;
    padding-top: 260px;
    padding-bottom: 20px;
  }

  .main-nav__list {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-bottom: 40px;
  }

  .main-nav__item {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-right: 0;
    padding-bottom: 29px;
    padding-top: 29px;
    border-bottom: 1px solid $color-default-white;
  }

  .main-nav__link {
    font-size: 23px;
    line-height: 34px;
    color: $color-default-white;
    transition: text-shadow $trans--400;

    &--active {
      opacity: 0.2;
    }
  }

  .main-nav__link:active,
  .main-nav__link--active:active {
    text-shadow: 0 0 0.6px $color-default-white, 0 0 0.6px $color-default-white;
  }

  .header__logo-icon {
    opacity: 1;
  }
}

.menu-burger .main-nav__list--language {
  flex-direction: row;
  justify-content: space-between;
  max-width: 103px;
  margin-bottom: 0;

  .main-nav__item {
    display: flex;
    width: 58px;
    padding: 0;
    border: none;

    &:first-child {
      justify-content: flex-end;
    }

    &:last-child {
      justify-content: flex-start;
    }
  }
}

.menu-burger__contacts {
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 0 15px;

  &-list {
    display: flex;
    flex-direction: column;
    max-width: 345px;
    width: 100%;
    padding-left: 0;
    list-style: none;
    margin-bottom: 0;
    padding-bottom: 16px;
  }

  &-item {
    margin-bottom: 18px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &-title {
    margin-top: 0;
    margin-bottom: -5px;
    font-size: 12px;
    line-height: 18px;
    font-weight: 600;
    color: $color-default-white;
  }

  &-link {
    font-size: 14px;
    line-height: 21px;
    font-weight: 400;
    text-decoration: underline;
    color: $color-default-white;
  }
}

.header--en .menu-burger {
  &__header {
    margin-bottom: 87px;
  }

  &__contacts-item {
    margin-bottom: 19px;
  }

  .main-nav {
    margin-bottom: 11px;
  }

  .main-nav__list:first-child {
    margin-bottom: 42px;
  }

  .main-nav__link {
    margin-right: 0;
  }

  .main-nav__item {
    padding-top: 29px;
    padding-bottom: 29px;
  }

  .main-nav__list--language {
    max-width: 107px;

    .main-nav__item {
      padding-top: 0;
      padding-bottom: 0;
    }
  }

  &--open {
    .menu-burger__header {
      position: relative;
      margin-bottom: 0;
    }
  }
}
