.job-openings {
  padding-top: size(240px);

  @include vp--767 {
    padding-top: 204px;
  }

  &__title {
    margin-bottom: size(57px);
    transition: opacity 1s;
    transition-timing-function: cubic-bezier(0.65, 0.05, 0.36, 1);
    opacity: 0;

    .show & {
      opacity: 1;
    }

    @include vp--767 {
      margin-bottom: 38px;
    }
  }

  &__inner {
    position: relative;
    display: flex;
    justify-content: space-between;
    padding-bottom: size(45px);

    @media (max-width: 1345px) {
      flex-direction: column;
      padding-bottom: 0;
    }
  }

  &__gif {
    width: size(380px);
    height: size(700px);
    position: absolute;
    right: 0;
    top: size(-231px);
    transition: opacity 1s;
    transition-timing-function: cubic-bezier(0.65, 0.05, 0.36, 1);
    opacity: 0;

    .show & {
      opacity: 1;
    }

    @include vp--1279 {
      position: static;
      align-self: center;
    }

    @include vp--767 {
      width: 264px;
      height: 487px;
      margin-bottom: -34px;
    }
  }

  &__text {
    margin: 0;
    max-width: size(785px);
    font-size: size(18px);
    line-height: size(27px);
    transition: transform 1s, opacity 1s;
    transform: translate3d(0, 50px, 0);
    transition-timing-function: cubic-bezier(0.65, 0.05, 0.36, 1);
    backface-visibility: hidden;
    opacity: 0;

    .show & {
      transform: translate3d(0, 0, 0);
      opacity: 1;
    }

    @include vp--767 {
      font-size: 14px;
      line-height: 24px;
      margin-bottom: -51px;
      max-width: 324px;
    }
  }
}
