.apps {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding-bottom: size(140px);

  @include vp--1279 {
    padding-bottom: 99px;
  }

  @include vp--767 {
    flex-direction: column-reverse;
    padding-bottom: 25px;
  }
}

.apps__list {
  display: flex;
  align-items: flex-start;
  align-self: center;
  max-width: 100%;
  overflow: visible;
  margin-top: size(-75px);

  @include vp--767 {
    margin-top: 0;
    margin-bottom: 75px;
  }
}

.apps__image {
  flex-shrink: 0;
  margin-bottom: size(35px);
  transition: transform 1s, opacity 1s;
  transition-timing-function: cubic-bezier(0.65, 0.05, 0.36, 1);
  transform: translate3d(0, 200px, 0);
  backface-visibility: hidden;
  opacity: 0;

  .show & {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }

  @include vp--1023 {
    margin-bottom: size(55px);
  }

  @include vp--767 {
    margin-bottom: 0;
    align-self: center;
  }

  img {
    width: size(400px);
    height: size(400px);

    @include vp--767 {
      width: 257px;
      height: 257px;
    }
  }
}
