.contacts {
  padding-top: size(102px);
  padding-bottom: size(209px);
  position: relative;

  @include vp--1023 {
    display: flex;
    flex-direction: column;
    padding-top: 50px;
    padding-bottom: 150px;
    align-items: center;
  }

  @include vp--767 {
    padding-top: 33px;
    padding-bottom: 74px;
  }
}

.contacts__text-wrapper {
  max-width: size(600px);
  width: 100%;
}

.contacts__heading {
  margin-bottom: size(18px);
  transition: opacity 1s;
  transition-timing-function: cubic-bezier(0.65, 0.05, 0.36, 1);
  opacity: 0;

  .show & {
    opacity: 1;
  }

  @include vp--767 {
    margin-bottom: 29px;
  }
}

.contacts__list {
  margin-top: 0;
  margin-bottom: 0;
  padding-left: 0;
  list-style: none;
}

.contacts__item {
  margin-bottom: size(48px);
  transition: transform 1s, opacity 1s;
  transform: translate3d(0, 100px, 0);
  transition-timing-function: cubic-bezier(0.65, 0.05, 0.36, 1);
  backface-visibility: hidden;
  opacity: 0;

  .show & {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }

  @include vp--767 {
    margin-bottom: 31px;
  }
}

.contacts__item:last-child {
  margin-bottom: 0;
}

.contacts__label {
  margin-bottom: size(3px);
  letter-spacing: -0.03em;
  font-size: size(16.7px);

  @include vp--767 {
    margin-bottom: 0;
  }
}

.contacts__link {
  text-decoration: underline;
  letter-spacing: -0.03em;
  transition: opacity $trans--300;

  @include vp--767 {
    font-size: 14px;
    line-height: 21px;
  }

  @media (hover: hover), screen and (min-width: 0\0) {
    &:hover,
    &:focus {
      opacity: 0.6;
    }

    &:active {
      opacity: 0.4;
    }
  }
}

.contacts__image {
  width: size(398px);
  height: size(398px);
  position: absolute;
  top: size(154px);
  right: size(161px);
  transition: transform 1s, opacity 1s;
  transition-timing-function: cubic-bezier(0.65, 0.05, 0.36, 1);
  transform: translate3d(0, 100px, 0);
  backface-visibility: hidden;
  opacity: 0;
  user-select: none;

  .show & {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }

  @include vp--1279 {
    right: 0;
  }

  @include vp--1023 {
    position: relative;
    top: auto;
    right: auto;
    margin-bottom: 100px;
  }

  @include vp--767 {
    width: 245px;
    height: 245px;
    margin-bottom: 85px;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transform: translateZ(0);
    transition: opacity 1s;
    pointer-events: none;
    user-select: none;
  }

  img:last-child {
    position: absolute;
    opacity: 0;
    top: 0;
    left: 0;
  }

  &.active {
    img {
      opacity: 0;
    }

    img:last-child {
      opacity: 1;
    }
  }
}
