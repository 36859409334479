.footer {
  background-color: $color-default-black;

  &__container {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding-top: size(38px);
    padding-bottom: size(41px);

    @include vp--1279 {
      padding-top: 38px;
      padding-bottom: 41px;
    }

    @include vp--1023 {
      padding-top: 38px;
      padding-bottom: 41px;
    }

    @include vp--767 {
      flex-direction: column;
      align-items: flex-start;
      padding-top: 54px;
      padding-bottom: 34px;
    }
  }

  &__inner {
    display: flex;
    justify-content: space-between;
    flex-shrink: 0;
    max-width: 47.5%;
    width: 100%;

    @include vp--1023 {
      flex-wrap: wrap;
    }
  }

  &__address,
  &__link {
    flex-shrink: 0;
    font-size: size(12px);
    line-height: size(18px);
    font-style: normal;
    color: $color-default-white;

    @include vp--1023 {
      order: 2;
      margin-right: 0;
    }

    @include vp--767 {
      font-size: 10px;
      line-height: 15px;
    }
  }

  &__link {
    transition: text-shadow $trans--400;

    @media (hover: hover), screen and (min-width: 0\0) {
      &:hover,
      &:focus {
        text-shadow: 0 0 size(0.6px) $color-default-white, 0 0 size(0.6px) $color-default-white;
      }
    }

    @include vp--1023 {
      order: 1;
      margin-bottom: 13px;
    }

    @include vp--767 {
      margin-bottom: 18px;
    }
  }

  &__address {
    margin-right: size(50px);

    @include vp--1023 {
      order: 2;
    }

    @include vp--767 {
      margin-bottom: 38px;
      margin-right: 0;
    }

    @media (max-width: 343px) {
      max-width: 200px;
    }
  }

  &__icon {
    width: size(240px);
    height: size(51px);

    @include vp--767 {
      width: 159px;
      height: 34px;
    }
  }
}

.footer--en {
  .footer__inner {
    max-width: 38.96%;
  }

  .footer__address {
    @media (max-width: 355px) {
      max-width: 200px;
    }
  }
}
