.about {
  padding-top: size(128px);
  padding-bottom: size(100px);

  @include vp--1279 {
    padding-top: 64px;
  }

  @include vp--1023 {
    padding-bottom: 50px;
  }

  @include vp--767 {
    padding-top: 48px;
    padding-bottom: 40px;
  }
}

.about__list {
  margin-top: 0;
  margin-bottom: 0;
  padding-left: 0;
  list-style: none;
  display: flex;
  flex-direction: column;
}

.about__item {
  display: flex;
  justify-content: space-between;
  margin-right: size(130px);
  transition: opacity 1s;
  transition-timing-function: cubic-bezier(0.65, 0.05, 0.36, 1);
  opacity: 0;

  .show & {
    opacity: 1;
  }

  @include vp--1279 {
    margin-right: 0;
  }

  @include vp--1023 {
    flex-direction: column;
    align-items: center;
  }
}

.about__item + .about__item {
  padding-top: size(137px);

  @include vp--1023 {
    padding-top: 100px;
  }

  @include vp--767 {
    padding-top: 49px;
  }
}

.about__item:nth-child(even) {
  flex-direction: row-reverse;
  margin-right: 0;

  @include vp--1023 {
    flex-direction: column;
  }

  .about__image-wrapper {
    margin-right: 0;
    margin-left: size(111px);

    @include vp--1279 {
      margin-left: 40px;
    }

    @include vp--1023 {
      margin-left: 0;
    }
  }
}

.about__image-wrapper {
  width: size(720px);
  height: size(380px);
  border-radius: size(25px);
  overflow: hidden;
  flex-shrink: 0;
  margin-right: size(111px);
  transition: transform 1s;
  transition-timing-function: cubic-bezier(0.65, 0.05, 0.36, 1);
  transform: translate3d(0, 50px, 0);
  backface-visibility: hidden;

  .show & {
    transform: translate3d(0, 0, 0);
  }

  @include vp--1279 {
    width: 540px;
    margin-right: 40px;
  }

  @include vp--1023 {
    width: 720px;
    margin-right: 0;
    margin-bottom: 20px;
    border-radius: 17px;
  }

  @include vp--767 {
    width: 100%;
    height: 182px;
    border-radius: 12px;
    margin-bottom: 27px;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.about__text-wrapper {
  max-width: size(480px);

  @include vp--1023 {
    max-width: 600px;
  }
}

.about__heading {
  margin-bottom: size(28px);
  transition: transform 1s;
  transition-timing-function: cubic-bezier(0.65, 0.05, 0.36, 1);
  transform: translate3d(0, 50px, 0);
  backface-visibility: hidden;

  .show & {
    transform: translate3d(0, 0, 0);
  }

  @include vp--767 {
    margin-bottom: 16px;
    width: 207px;
  }
}

.about__description {
  margin-top: 0;
  margin-bottom: 0;
  transition: transform 1s;
  transform: translate3d(0, 100px, 0);
  transition-timing-function: cubic-bezier(0.65, 0.05, 0.36, 1);
  backface-visibility: hidden;

  .show & {
    transform: translate3d(0, 0, 0);
  }

  @include vp--767 {
    font-size: 14px;
    line-height: 24px;
  }
}

.about__item--mission {
  margin-top: size(-28px);
  margin-right: 0;
  justify-content: flex-start;

  @include vp--1279 {
    margin-right: 0;
  }

  @include vp--1023 {
    margin-top: 0;
  }

  @include vp--767 {
    margin-top: 47px;
  }

  .about__title {
    margin-bottom: size(20px);
  }

  .about__heading-wrapper {
    max-width: size(609px);

    @include vp--1023 {
      max-width: 600px;
      width: 100%;
    }
  }

  .about__heading {
    margin-bottom: 0;
    max-width: 90%;

    @include vp--1279 {
      max-width: 100%;
    }

    @include vp--1023 {
      margin-bottom: 28px;
    }

    @include vp--767 {
      margin-bottom: 15px;
      width: 263px;
    }
  }

  .about__text-wrapper {
    padding-top: size(55px);
    margin-left: size(111px);

    @include vp--1279 {
      margin-left: 40px;
      max-width: 400px;
    }

    @include vp--1023 {
      margin-left: 0;
      padding-top: 0;
      max-width: 600px;
    }
  }

  .about__description {
    width: size(504px);

    @include vp--767 {
      width: 337px;
    }
  }
}
