.request {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-top: size(45px);
  padding-bottom: size(138px);
  transition: transform 1s, opacity 1s;
  transition-timing-function: cubic-bezier(0.65, 0.05, 0.36, 1);
  transform: translateY(100px);
  opacity: 0;

  &.show {
    transform: translateY(0);
    opacity: 1;
  }

  @include vp--1279 {
    flex-direction: column;
    align-items: center;
  }

  @include vp--767 {
    padding-top: 10px;
    padding-bottom: 76px;
  }

  &__privacy-police {
    margin: 0;
    font-size: size(12px);
    line-height: size(18px);

    a {
      text-decoration: underline;
      position: relative;
      transition: text-shadow $trans--400;

      @media (hover: hover), screen and (min-width: 0\0) {
        &:hover,
        &:focus {
          text-shadow: 0 0 size(0.6px) $color-default-black, 0 0 size(0.6px) $color-default-black;
        }
      }
    }

    @include vp--767 {
      font-size: 8.62px;
      line-height: 13px;
    }
  }

  &__inner {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  &__gif {
    width: size(583px);
    height: size(104px);
    margin-bottom: size(46px);
    margin-left: size(-8px);

    @include vp--1279 {
      align-self: center;
      order: 1;
      margin-bottom: 155px;
    }

    @include vp--767 {
      max-width: 345px;
      width: 100%;
      height: 65px;
      margin-bottom: 80px;
      margin-left: 0;
      object-fit: contain;
    }

    @media (max-width: 350px) {
      max-width: 290px;
      height: 65px;
    }
  }

  &__title {
    margin: 0;
    max-width: size(832px);

    @include vp--1279 {
      align-self: flex-start;
      order: 2;
      max-width: 1125px;
      margin-bottom: 50px;
    }

    @include vp--767 {
      max-width: 600px;
      margin-bottom: 47px;
    }
  }
}

.request__form {
  display: flex;
  flex-direction: column;
  max-width: size(479px);
  margin-top: size(17px);
  padding-right: size(32px);

  @include vp--1279 {
    align-items: center;
    justify-content: center;
    max-width: 479px;
    padding-right: 0;
  }

  @include vp--767 {
    margin-top: 0;
  }

  button {
    padding: size(24px) size(20px);
    max-width: size(409px);
    width: 100%;
    min-height: size(86px);
    color: $color-default-black;
    background-color: $color-transparent;
    border: size(1px) solid $color-default-black;
    border-radius: size(25px);
    text-align: center;
    font-size: size(24px);
    line-height: size(36px);
    font-weight: 600;
    text-transform: uppercase;
    cursor: pointer;
    transition: background-color $trans--300, color $trans--300, opacity $trans--300;
    opacity: 1;
    z-index: 1;
    position: relative;


    @include vp--767 {
      padding: 14px 20px;
      max-width: 100%;
      min-height: 61px;
      font-size: 14px;
      line-height: 21px;
      border-radius: 18px;
    }

    &.active,
    &:active {
      background-color: $color-default-black;
      color: $color-default-white;
    }
  }

  .request__privacy-police {
    max-width: size(450px);
  }

  .request__sent-block {
    position: relative;
    margin-top: size(87px);
    margin-bottom: size(20px);
    max-width: size(409px);
    width: 100%;

    @include vp--767 {
      margin-top: 63px;
      margin-bottom: 15px;
      max-width: 100%;
    }
  }

  .request__form-sent {
    opacity: 0;
    transition: opacity $trans--300;
    position: absolute;
    padding: size(24px) size(20px);
    top: 55%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
    margin: 0;
    width: 100%;
    text-align: center;
    font-size: size(24px);
    line-height: size(36px);
    font-weight: 600;
    text-transform: uppercase;
    z-index: 0;

    @include vp--767 {
      font-size: 14px;
      line-height: 21px;
    }
  }

  &.sent {
    button {
      opacity: 0;
    }

    .request__form-sent {
      opacity: 1;
    }
  }
}

.request__form .custom-input {
  position: relative;
  width: 100%;
  margin-bottom: size(10px);

  @include vp--767 {
    margin-bottom: 7px;
  }
}

.request__form .custom-input input,
.request__form .custom-input textarea {
  width: 100%;
  padding-top: size(12px);
  padding-right: size(15px);
  padding-bottom: size(10px);
  padding-left: size(15px);
  font-size: size(16px);
  line-height: size(24px);
  background-color: $color-solitude;
  border: none;
  outline: none;
  border-bottom: size(1px) solid $color-default-black;
  border-radius: 0;

  @include vp--767 {
    padding-top: 9px;
    padding-right: 12px;
    padding-left: 12px;
    padding-bottom: 3px;
    font-size: 16px;
    line-height: 21px;
  }
}

.request__form .custom-input--error {
  .custom-input__error {
    opacity: 1;
  }

  input,
  textarea {
    border-color: $color-red;
  }
}

.request__form label {
  display: flex;
  flex-direction: column;
  font-size: size(14px);
  line-height: size(21px);
  cursor: pointer;

  @include vp--767 {
    font-size: 10px;
    line-height: 15px;
  }
}

.request__form label .custom-input__error {
  position: absolute;
  bottom: size(-20px);
  right: 0;
  font-size: size(12px);
  line-height: size(18px);
  color: $color-red;
  opacity: 0;

  @include vp--767 {
    font-size: size(10px);
    line-height: size(15px);
    bottom: size(-17px);
  }
}

// --Vacancy

.request--vacancy {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  padding-top: size(65px);
  padding-bottom: size(148px);

  @include vp--1279 {
    display: flex;
    flex-direction: column;
    align-items: stretch;
  }

  @include vp--767 {
    padding-bottom: 75px;
    padding-top: 27px;
  }

  @media (max-width: 1100px) {
    align-items: center;
    justify-content: center;
  }

  .request__title {
    order: 0;
    max-width: size(620px);
    margin-bottom: size(29px);

    @include vp--767 {
      margin-bottom: 48px;
      text-align: left;
    }
  }

  .request__form {
    display: flex;
    max-width: size(1309px);
    flex-direction: column;
    padding-right: 0;

    @include vp--1279 {
      margin-right: auto;
      margin-left: auto;
      max-width: 479px;
    }
  }

  .request__form .custom-input {
    @include vp--767 {
      margin-bottom: size(7px);
    }
  }

  .request__form-inner {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;

    @include vp--1279 {
      flex-direction: column;
      width: 100%;
    }
  }

  .request__form-column {
    display: flex;
    flex-direction: column;
    max-width: size(479px);
    width: 100%;

    &--left {
      margin-top: size(7px);
      margin-right: size(76px);

      @media (max-width: 1100px) {
        margin-right: 0;
      }
    }
  }

  .request__sent-block {
    margin-top: size(30px);

    @include vp--767 {
      margin-top: 58px;
      margin-bottom: 15px;
    }
  }

  .request__privacy-police {
    max-width: size(450px);

    @include vp--1279 {
      max-width: 100%;
    }
  }
}
