.growth {
  padding-bottom: size(115px);

  @include vp--1279 {
    padding-bottom: 65px;
  }

  @include vp--1023 {
    display: none;
  }
}

.growth__heading {
  transition: opacity 1s;
  transition-timing-function: cubic-bezier(0.65, 0.05, 0.36, 1);
  opacity: 0;

  .show & {
    opacity: 1;
  }

  @include vp--1279 {
    margin-bottom: 20px;
  }
}

.growth__chart {
  margin-top: size(-50px);
  width: size(1468px);
  height: size(653px);
  position: relative;
  margin-left: auto;
  margin-right: auto;
  transition: transform 1s, opacity 1s;
  transition-timing-function: cubic-bezier(0.65, 0.05, 0.36, 1);
  transform: translate3d(0, 50px, 0);
  backface-visibility: hidden;
  opacity: 0;

  .show & {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }

  @include vp--1279 {
    margin-top: 0;
    width: 100%;
    height: 100%;
  }

  img {
    position: absolute;
    width: size(1468px);
    height: size(653px);
    object-fit: cover;

    @include vp--1279 {
      position: relative;
      width: 100%;
      height: 100%;
    }
  }
}
