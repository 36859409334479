.preloader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100vh;
  //stylelint-disable-next-line
  height: calc(100 * var(--vh, 1vh));
  z-index: 1100;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $color-solitude;


  &__logo {
    opacity: 0;
    transition: opacity 1s ease-in-out;
    position: relative;

    img {
      width: size(248px);
      height: size(99px);
    }

    picture:last-child img {
      opacity: 0;
      animation: opacity 2s infinite;
      position: absolute;
      top: 0;
      left: 0;
    }
  }

  &.is-loading {
    .preloader__logo {
      opacity: 1;
    }
  }

  &.is-ready {
    opacity: 0;
    transition: opacity 1.2s 1s;

    .preloader__logo {
      opacity: 0;
      transition: opacity 0.4s ease-in-out;
    }
  }

  &.is-hidden,
  &:not(.js-preloader) {
    display: none;
  }
}

@keyframes opacity {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}
