.advantages {
  padding-top: size(219px);
  padding-bottom: size(107px);

  @include vp--1023 {
    padding-top: 102px;
  }

  @include vp--767 {
    padding-top: 41px;
    padding-bottom: 72px;
  }
}

.advantages__columns {
  display: flex;
  justify-content: space-between;
  padding-right: size(130px);

  padding-right: size(100px);

  @include vp--1279 {
    padding-right: 65px;
  }

  @include vp--1023 {
    padding-right: 35px;
  }

  @include vp--767 {
    flex-direction: column;
    padding-right: 0;
  }
}

.advantages__column {
  display: flex;
  flex-direction: column;
  width: 100%;

  max-width: size(512px);

  @include vp--1279 {
    max-width: 380px;
  }

  @include vp--1023 {
    max-width: 280px;
  }

  @include vp--767 {
    margin-bottom: 48px;
    max-width: none;
  }
}

.advantages__column:last-child {
  padding-top: size(182px);

  @include vp--767 {
    padding-top: 0;
    margin-bottom: 0;
  }
}

.advantages__item {
  margin-bottom: size(125px);
  border-bottom: size(1px) solid $color-transparent;
  padding-bottom: size(37px);
  position: relative;

  @include vp--767 {
    transform: translate3d(0, 200px, 0);
    transition: max-width 1s, opacity 1s, transform 1s;
    transition-timing-function: cubic-bezier(0.65, 0.05, 0.36, 1);
    backface-visibility: hidden;

    .show & {
      transform: translate3d(0, 0, 0);
    }
  }

  &::before {
    content: "";
    position: absolute;
    background-color: $color-default-black;
    bottom: size(-1px);
    left: 0;
    width: 100%;
    height: size(1px);
    max-width: 0;
    opacity: 0;
    transform: translate3d(0, 200px, 0);
    transition: max-width 1s, opacity 1s, transform 1s;
    transition-timing-function: cubic-bezier(0.65, 0.05, 0.36, 1);
    backface-visibility: hidden;

    @include vp--767 {
      transform: translate3d(0, 0, 0);
    }
  }

  .show &::before {
    max-width: 100%;
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }

  @include vp--767 {
    margin-bottom: 48px;
    padding-bottom: 19px;

    br {
      display: none;
    }
  }
}

.advantages__item:last-child {
  margin-bottom: 0;
  transition: transform 1s;
  transition-timing-function: cubic-bezier(0.65, 0.05, 0.36, 1);
  transform: translate3d(0, 200px, 0);
  backface-visibility: hidden;

  .show & {
    transform: translate3d(0, 0, 0);
  }
}

.advantages__heading {
  font-weight: 600;
  font-size: size(30px);
  line-height: size(45px);
  margin-top: 0;
  opacity: 0;
  margin-bottom: size(23px);
  transition: transform 1s, opacity 1s;
  transition-timing-function: cubic-bezier(0.65, 0.05, 0.36, 1);
  transform: translate3d(0, 50px, 0);
  backface-visibility: hidden;

  .show & {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }

  @include vp--767 {
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 12px;
    transform: translate3d(0, 0, 0);
  }
}

.advantages__description {
  margin-top: 0;
  margin-bottom: 0;
  transition: transform 1s, opacity 1s;
  transform: translate3d(0, 100px, 0);
  transition-timing-function: cubic-bezier(0.65, 0.05, 0.36, 1);
  opacity: 0;
  backface-visibility: hidden;

  .show & {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }

  @include vp--767 {
    font-size: 14px;
    line-height: 21px;
    transform: translate3d(0, 0, 0);
  }
}
