.container {
  width: 100%;
  max-width: size(1520px);
  margin: 0 auto;
  padding: 0 size(40px);

  @include vp--1279 {
    padding: 0 32px;
  }

  @include vp--1023 {
    padding: 0 24px;
  }

  @include vp--767 {
    padding: 0 15px;
  }

  &--no-padding {
    @include vp--767 {
      padding: 0;
    }
  }

  &--vacancy {
    padding-right: size(59px);
  }

  &--vacancy-large {
    max-width: size(1574px);
    padding-right: size(25px);
    padding-left: size(67px);
  }

  &--vacancy-extralarge {
    max-width: size(1574px);
    padding-right: size(5px);
    padding-left: size(67px);
  }
}
