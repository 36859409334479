.header {
  position: absolute;
  top: 0;
  left: 0;

  display: flex;
  align-items: center;
  width: 100%;
  z-index: 10;
  background-color: $color-solitude;
  backface-visibility: hidden;

  &--fixed {
    position: fixed;
    top: size(-136px);

    &.animate {
      transform: translate3d(0, 100%, 0);
      transition: transform $trans--600;
    }
  }

  &__wrapper {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding-top: size(39px);
    padding-bottom: size(43px);
    margin-right: size(40px);
    margin-left: size(40px);
    border-bottom: size(1px) solid $color-transparent;
    max-width: size(1440px);
    margin: 0 auto;

    @include vp--1279 {
      max-width: 100%;
      margin: 0 auto;
      margin-left: 32px;
      margin-right: 32px;
    }

    @include vp--1023 {
      margin-left: 24px;
      margin-right: 24px;
    }

    @include vp--767 {
      margin-left: 15px;
      margin-right: 15px;
      padding-top: 54px;
      padding-bottom: 34px;
    }

    &::before {
      content: "";
      position: absolute;
      background-color: $color-default-black;
      bottom: size(-1px);
      left: 0;
      width: 100%;
      height: size(1px);
      max-width: 0;
      transition: max-width 1s;
    }

    .show &::before {
      max-width: 100%;
    }
  }
}

.header__logo {
  width: size(114px);
  height: size(46px);
  position: relative;
  margin-left: size(-6px);
  transition: transform 1s, opacity 1s;
  transform: translate3d(0, -50px, 0);
  backface-visibility: hidden;
  opacity: 0;

  .show & {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }

  @include vp--767 {
    margin: 0 auto;
  }

  &.active {
    .header__logo-icon {
      opacity: 0;
    }

    .header__logo-icon:last-child {
      opacity: 1;
    }
  }
}

.header__logo-icon {
  position: absolute;
  top: -3px;
  left: 0;
  width: size(114px);
  height: size(46px);
  transition: opacity 1s;
  opacity: 1;

  &:last-child {
    opacity: 0;
  }

  @include vp--767 {
    top: -4px;
  }
}

.header__previous-page {
  position: absolute;
  display: none;
  padding: 0;
  color: $color-default-black;

  background-color: $color-transparent;
  border: 0;

  @include vp--767 {
    display: flex;
  }

  svg {
    fill: $color-default-black;
  }
}

.header__toggle {
  position: absolute;
  z-index: 2;

  display: none;
  width: size(25px);
  height: size(20px);
  padding: 0;

  background-color: $color-transparent;
  border: 0;

  @include vp--767 {
    display: block;
  }

  span {
    position: absolute;
    display: block;
    width: size(25px);
    height: size(1px);
    backface-visibility: hidden;
    background-color: $color-default-black;

    &:nth-of-type(1) {
      transform: translate3d(0, 10px, 0);
    }

    &:nth-of-type(3) {
      transform: translate3d(0, -10px, 0);
    }
  }
}

.header--fixed {
  @include vp--1279 {
    top: -106px;
  }

  .header__wrapper {
    @include vp--1279 {
      padding-top: 24px;
      padding-bottom: 28px;
    }
  }
}
