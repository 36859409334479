.h1 {
  font-weight: 600;
  font-size: size(64px);
  line-height: size(96px);
  margin-top: 0;
  margin-bottom: 0;

  @include vp--767 {
    font-size: 23px;
    line-height: 34px;
  }
}

.h2 {
  font-weight: 600;
  font-size: size(30px);
  line-height: size(45px);
  margin-top: 0;
  margin-bottom: 0;

  @include vp--767 {
    font-size: 16px;
    line-height: 24px;
  }
}

.h3 {
  font-weight: 600;
  font-size: size(16px);
  line-height: size(24px);
  margin-top: 0;
  margin-bottom: 0;

  @include vp--767 {
    font-size: 14px;
    line-height: 21px;
  }
}
